<template>
  <li class="menu-item">
    <span class="form-label mr-auto">
      <i class="mr-3" :class="'i-' + icon"></i>
      {{ placeholder }}
    </span>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="zip_3d"
      :download="zip_3d"
      :href="zip_3d"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="usdz_web_3d"
      :download="usdz_web_3d"
      :href="usdz_web_3d"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <!-- <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="gltf_web_3d"
      :download="gltf_web_3d"
      :href="gltf_web_3d"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a> -->

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="gltf_3d"
      :download="gltf_3d"
      :href="gltf_3d"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a class="btn btn-sm bg-white md-down:p-0" v-if="sfb_3d" :download="sfb_3d" :href="sfb_3d">
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="ios_mask_model"
      :download="ios_mask_model"
      :href="ios_mask_model"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="ios_mask_tex"
      :download="ios_mask_tex"
      :href="ios_mask_tex"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="sfb_mask"
      :download="sfb_mask"
      :href="sfb_mask"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="sfb_tex_mask"
      :download="sfb_tex_mask"
      :href="sfb_tex_mask"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="ios_mask_legacy"
      :download="ios_mask_legacy"
      :href="ios_mask_legacy"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="ios_mask_legacy_tex"
      :download="ios_mask_legacy_tex"
      :href="ios_mask_legacy_tex"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="web_mask"
      target="_blank"
      :href="
        `${mask_preview_url}/?modif=${modif.idt_model_modif}&mask=${web_mask}`
      "
    >
      <i class="i-eye float-left md-down:d-none"></i>
      <i class="i-eye lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_prev") }}</span>
    </a>

    <a
      class="btn btn-sm bg-white md-down:p-0"
      v-if="web_mask"
      :download="web_mask"
      :href="web_mask"
    >
      <i class="i-download float-left md-down:d-none"></i>
      <i class="i-download lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_dl") }}</span>
    </a>

    <span class="btn btn-sm bg-white md-down:p-0" @click="playAudio" v-if="audio">
      <i class="i-play float-left md-down:d-none"></i>
      <i class="i-play lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("audio_play") }}</span>
    </span>

    <mega-button class="btn-sm bg-white md-down:p-0" @click="$refs['file'].click()">
      <i class="i-upload float-left md-down:d-none"></i>
      <i class="i-upload lg-up:d-none"></i>
      <span class="md-down:d-none">{{ $t("model_arch_upl") }}</span>
    </mega-button>

    <input class="input-file" hidden type="file" ref="file" :accept="accept" @change="addFiles" />
  </li>
</template>

<script>
export default {
  name: "ModelFile",
  props: ["type", "placeholder", "modif", "mask", "accept"],
  data() {
    return {
      file: undefined,
      mask_ios: this.mask ? this.mask["ios"] : null,
      mask_android: this.mask ? this.mask["android"] : null,
      mask_web: this.mask ? this.mask["web"] : null,
      android_sfb: null,
      android_tex: null,
      ios_model: null,
      ios_tex: null,
      mask_preview_url:
        window.location.origin.includes("gora.studio") ||
        process.env.NODE_ENV !== "production"
          ? "https://webar.gora.studio"
          : "https://webar.arround.business"
    };
  },
  computed: {
    usdz_web_3d() {
      return this.type === "usdz_web_3d"
        && this.modif["three_d"]['web_usdz']
        && this.modif["three_d"]['web_usdz']['url'];
    },
    gltf_web_3d() {
      return this.type === "gltf_web_3d"
        && this.modif["three_d"]['web_gltf']
        && this.modif["three_d"]['web_gltf']['url'];
    },
    zip_3d() {
      return this.type === "apple_3d" && this.modif["url_archive"];
    },
    ios_mask_legacy() {
      if (!this.modif.mask) return "";
      return this.type === "ios_mask_legacy" &&
        this.modif.mask.hasOwnProperty("ios_legacy") &&
        this.modif.mask.ios_legacy.hasOwnProperty("zip")
        ? this.modif.mask.ios_legacy.zip.url
        : "";
    },
    ios_mask_legacy_tex() {
      if (!this.modif.mask) return "";
      return this.type === "ios_mask_legacy_tex" &&
        this.modif.mask.hasOwnProperty("ios_legacy") &&
        this.modif.mask.ios_legacy.hasOwnProperty("texture")
        ? this.modif.mask.ios_legacy.texture.url
        : "";
    },
    sfb_3d() {
      return this.type === "android_3d" && this.modif["url_sfb"];
    },
    gltf_3d() {
      return this.type === "web_3d"
        && this.modif["three_d"]['web_gltf']
        && this.modif["three_d"]['web_gltf']['url'];
    },
    ios_mask_model() {
      return this.type === "apple_mask_zip" && this.mask_ios && this.ios_model
        ? this.ios_model["url"]
        : "";
    },
    ios_mask_tex() {
      return this.type === "apple_mask_tex" && this.mask_ios && this.ios_tex
        ? this.ios_tex["url"]
        : "";
    },
    sfb_mask() {
      return this.type === "android_sfb_mask" &&
        this.mask_android &&
        this.android_sfb
        ? this.android_sfb["url"]
        : "";
    },
    sfb_tex_mask() {
      return this.type === "android_tex_mask" &&
        this.mask_android &&
        this.android_tex
        ? this.android_tex["url"]
        : "";
    },
    web_mask() {
      return this.type === "web_mask" && this.mask_web
        ? this.mask_web["zip"]["url"]
        : "";
    },
    audio() {
      return this.type === "music" && this.modif["audio"];
    },
    icon() {
      switch (this.type) {
        case "apple_3d":
        case "apple_mask_zip":
        case "apple_mask_tex":
        case "ios_mask_legacy":
        case "ios_mask_legacy_tex":
          return "apple";
          break;
        case "android_3d":
        case "android_sfb_mask":
        case "android_tex_mask":
          return "android";
          break;
        case 'web_3d':
        case "web_mask":
        case "usdz_web_3d":
          return "globe";
          break;
        case "music":
          return "music";
          break;
      }
    }
  },
  mounted() {
    this.eventListener(
      this.$el,
      "drag dragstart dragend dragover dragenter dragleave drop",
      e => {
        e.preventDefault();
        e.stopPropagation();
      }
    );

    this.android_sfb = this.mask_android ? this.mask_android["sfb"] : null;
    this.android_tex = this.mask_android ? this.mask_android["texture"] : null;
    this.ios_model = this.mask_ios ? this.mask_ios["zip"] : null;
    this.ios_tex = this.mask_ios ? this.mask_ios["texture"] : null;

    this.eventListener(this.$el, "dragover dragenter", () => this.dragEnter());
    this.eventListener(this.$el, "dragleave dragend drop", () =>
      this.dragEnd()
    );
    this.eventListener(this.$el, "drop", e => this.dragDropped(e));
  },
  methods: {
    playAudio() {
      let audio = document.createElement("audio");
      audio.src = this.modif["audio"]["url"];
      audio.play();

      audio.onended = function() {
        audio.outerHTML = "";
      };
    },

    target() {
      this.$refs["file"].click();
    },

    addFiles(event) {
      let files = event.target.files;

      if (files) this.file = files[0];

      this.$emit("input", this.file);

      let formData = new FormData();
      formData.append("idt_model_modif", this.modif["idt_model_modif"]);

      let notification = this.$t("succ_andr_arch_upd");

      if (this.type === "android_3d") formData.append("sfb", this.file);

      if (this.type === "web_3d") {
        formData.append("gltf", this.file);
        notification = this.$t("succ_web_arch_upd");
      }

      if (this.type === "apple_3d") {
        formData.append("archive", this.file);
        notification = this.$t("succ_ios_arch_upd");
      }

      if (this.type === "music") {
        formData.append("audio", this.file);
        notification = this.$t("succ_audio_upd");
      }

      if (this.type === "android_sfb_mask") {
        formData.append("mask_android_sfb", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }

      if (this.type === "android_tex_mask") {
        formData.append("mask_android_texture", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }


      if (this.type === "ios_mask_legacy") {
        formData.append("mask_ios_legacy_zip", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }

      if (this.type === "ios_mask_legacy_tex") {
        formData.append("mask_ios_legacy_texture", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }


      if (this.type === "apple_mask_zip") {
        formData.append("mask_ios_zip", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }

      if (this.type === "apple_mask_tex") {
        formData.append("mask_ios_texture", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }

      if (this.type === "web_mask") {
        formData.append("mask_web_zip", this.file);
        notification = this.$t("succ_mask_arch_upd");
      }

      if (this.type === "usdz_web_3d") {
        formData.append("usdz", this.file);
        notification = this.$t("succ_web_arch_upd");
      }
      if (this.type === "usdz_web_3d") {
        formData.append("usdz", this.file);
        notification = this.$t("succ_web_arch_upd");
      }
      // if (this.type === "gltf_web_3d") {
      //   formData.append("gltf", this.file);
      //   notification = this.$t("succ_portal_arch_upd");
      // }
      // formData.append('idt_model', this.modif.idt_model)

      this.$api.v2
        .put("/user/modif", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          event.target.value = "";
          this.file = undefined;
          this.$emit("drop");
          this.$alert.success(notification);
        })
        .catch(error => console.log(error));
    },

    addEvent(obj, type, fn) {
      if (obj.addEventListener) {
        obj.addEventListener(type, fn, false);
      } else if (obj.attachEvent) {
        obj.attachEvent("on" + type, function() {
          return fn.call(obj, window.event);
        });
      }
    },

    eventListener(el, events, listener) {
      events = events.split(" ");

      events.map(event => {
        this.addEvent(el, event, listener);
      });
    },

    dragEnter() {
      this.$el.classList.add("drag-hover");
    },

    dragEnd() {
      this.$el.classList.remove("drag-hover");
    },

    dragDropped(e) {
      if (e.dataTransfer.items) this.file = e.dataTransfer.items[0].getAsFile();

      this.$emit("input", this.file);
      this.$emit("drop");
    }
  }
};
</script>
