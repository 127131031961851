var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "menu-item" },
    [
      _c("span", { staticClass: "form-label mr-auto" }, [
        _c("i", { staticClass: "mr-3", class: "i-" + _vm.icon }),
        _vm._v("\n    " + _vm._s(_vm.placeholder) + "\n  ")
      ]),
      _vm._v(" "),
      _vm.zip_3d
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.zip_3d, href: _vm.zip_3d }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.usdz_web_3d
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.usdz_web_3d, href: _vm.usdz_web_3d }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.gltf_3d
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.gltf_3d, href: _vm.gltf_3d }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sfb_3d
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.sfb_3d, href: _vm.sfb_3d }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ios_mask_model
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.ios_mask_model, href: _vm.ios_mask_model }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ios_mask_tex
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.ios_mask_tex, href: _vm.ios_mask_tex }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sfb_mask
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.sfb_mask, href: _vm.sfb_mask }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sfb_tex_mask
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.sfb_tex_mask, href: _vm.sfb_tex_mask }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ios_mask_legacy
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: {
                download: _vm.ios_mask_legacy,
                href: _vm.ios_mask_legacy
              }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.ios_mask_legacy_tex
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: {
                download: _vm.ios_mask_legacy_tex,
                href: _vm.ios_mask_legacy_tex
              }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.web_mask
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: {
                target: "_blank",
                href:
                  _vm.mask_preview_url +
                  "/?modif=" +
                  _vm.modif.idt_model_modif +
                  "&mask=" +
                  _vm.web_mask
              }
            },
            [
              _c("i", { staticClass: "i-eye float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-eye lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_prev")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.web_mask
        ? _c(
            "a",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              attrs: { download: _vm.web_mask, href: _vm.web_mask }
            },
            [
              _c("i", { staticClass: "i-download float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-download lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("model_arch_dl")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.audio
        ? _c(
            "span",
            {
              staticClass: "btn btn-sm bg-white md-down:p-0",
              on: { click: _vm.playAudio }
            },
            [
              _c("i", { staticClass: "i-play float-left md-down:d-none" }),
              _vm._v(" "),
              _c("i", { staticClass: "i-play lg-up:d-none" }),
              _vm._v(" "),
              _c("span", { staticClass: "md-down:d-none" }, [
                _vm._v(_vm._s(_vm.$t("audio_play")))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "mega-button",
        {
          staticClass: "btn-sm bg-white md-down:p-0",
          on: {
            click: function($event) {
              return _vm.$refs["file"].click()
            }
          }
        },
        [
          _c("i", { staticClass: "i-upload float-left md-down:d-none" }),
          _vm._v(" "),
          _c("i", { staticClass: "i-upload lg-up:d-none" }),
          _vm._v(" "),
          _c("span", { staticClass: "md-down:d-none" }, [
            _vm._v(_vm._s(_vm.$t("model_arch_upl")))
          ])
        ]
      ),
      _vm._v(" "),
      _c("input", {
        ref: "file",
        staticClass: "input-file",
        attrs: { hidden: "", type: "file", accept: _vm.accept },
        on: { change: _vm.addFiles }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }